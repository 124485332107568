<template>
	<el-dialog :visible.sync="showDialog" :before-close="handleClose" width="800" :show-close="false">
		<div slot="title" class="d-flex flex-row align-items-center justify-content-between">
			<h6>品类选择</h6>
			<div>
				<el-button @click="showDialog = false" size="mini">取 消</el-button>
				<el-button type="primary" @click="confirmSelection" size="mini">确 定</el-button>
			</div>
		</div>
		<treeselect
			id="sales-tree"
			v-model="selectedCategories"
			:multiple="true"
			:options="options"
			placeholder=""
			search-nested
			value-consists-of="ALL"
		>
			<label slot="option-label" slot-scope="{ node }" style="transform:translateY(2px);">
				{{ node.label }}
			</label>
			<div slot="value-label" slot-scope="{ node }">{{ node.raw.completeName }}</div>
		</treeselect>
	</el-dialog>
</template>

<script>
	import Treeselect from '@riophae/vue-treeselect'
	import Common from '@/common/mixins/common.js'
	export default {
		mixins: [Common],
		components: {
			Treeselect
		},
		props: {
			visible: {
				type: Boolean,
				default: true
			},
			options: {
				type: Array,
				default: (() => [])
			},
			categories: {
				type: Array,
				default: (() => [])
			}
		},
		data() {
			return {
				selectedCategories: []
			}
		},
		watch: {
			visible(newVal, oldVal) {
				if (newVal) {
					this.selectedCategories = [...this.categories]
				}
			}
		},
		computed: {
			showDialog: {
				get() {
					return this.visible
				},
				set(val) {
					this.$emit('update:visible', val)
				}
			}
		},
		methods: {
			confirmSelection() {
				this.$emit('update:categories', this.selectedCategories)
				this.showDialog = false
			}
		}
	}
</script>

<style lang="scss">
	#sales-tree {
		.vue-treeselect__control {
			height: 28px !important;
		}
		.vue-treeselect__multi-value-item {
			border-radius: 10px;
			background-color: #f4f4f5;
			border: 1px solid #e9e9eb;
			color: #909399;
			span {
				font-size: 10px;
			}
		}
		.vue-treeselect__icon.vue-treeselect__value-remove {
			color: #909399;
		}
	}
	div.vue-treeselect__multi-value {
	    max-height: 150px;
	    overflow-y: auto;
	}
</style>
