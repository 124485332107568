<template>
	<main-container :showHeader="false">
		<el-card>
			<el-result v-if="hasErr" icon="error" title="错误提示" subTitle="网络错误,请稍后重试">
				<template slot="extra">
					<el-button type="primary" size="medium" @click="__init()">刷新</el-button>
				</template>
			</el-result>
			<template v-else>
				<advanced-search-bar>
					<template>
						<form-item label="站点:">
							<el-select v-model="queryForm.websites" size="mini" filterable>
								<el-option v-for="item in websites" :label="item.key" :value="item.key"></el-option>
							</el-select>
						</form-item>
						<form-item>
							<el-button type="primary" @click="searchEvent" icon="el-icon-search" size="mini">查询</el-button>
							<el-button type="primary" size="mini" @click="downloadTask">下载</el-button>
						</form-item>
					</template>
					<template #advanced>
						<form-item label="选择日期:">
							<el-date-picker v-model="queryForm.date" type="daterange" size="mini" :clearable="false" value-format="yyyy-MM-dd">
							</el-date-picker>
						</form-item>
						<form-item label="国家:" style="width:400px;" >
							<el-select value="" size="mini" class="w-75 no-poper" @focus="triggerCountry"
								:popper-append-to-body="false" empty>
							</el-select>
							<small class="w-25 text-primary ml-1" style="cursor:pointer;"
								@click="triggerCountry">已选择{{queryForm.countryCodes.length}}个</small>
						</form-item>
						<form-item label="上架时间:" style="width:300px">
							<el-date-picker v-model="queryForm.publishAt" type="daterange" size="mini" value-format="yyyy-MM-dd">
							</el-date-picker>
						</form-item>
						<form-item label="商品品类:">
							<el-select size="mini" @focus="visible.category=true" value="" empty :popper-append-to-body="false">
							</el-select>
							<small v-if="queryForm.categories.length" class="text-primary ml-2" style="cursor:pointer;" @click="visible.category=true">已选择{{queryForm.categories.length}}个品类</small>
						</form-item>
						<form-item label="商品 ID:">
							<el-input v-model="queryForm.spus" size="mini"></el-input>
						</form-item>
						<form-item label="聚合展示:">
							<el-checkbox-group v-model="queryForm.aggregateBys" @change="queryForm.aggregate=queryForm.aggregateBys.length>0" size="mini" class="sale-aggregate-checkbox-group">
								<el-checkbox label="DATE" key="DATE">日期</el-checkbox>
								<el-checkbox label="COUNTRY" key="COUNTRY">国家</el-checkbox>
							</el-checkbox-group>
						</form-item>
					</template>
				</advanced-search-bar>
				<div style="background-color:#DCDFE6;height:1px;margin:0 0 24px -20px;width:calc(100% + 40px);"></div>
				<el-table height="auto" :data="tableData" @sort-change="sortChange" :default-sort ="{prop: 'orderQuantity', order: 'descending'}">
					<el-table-column label="Day" min-width="150">
						<template slot-scope="scope">
							<div v-if="/^[a-z]+$/i.test(scope.row.reportDate)">
								{{scope.row.reportDate}}
							</div>
							<div v-else>{{scope.row.reportDate|formatDate('YYYY-MM-DD', false)}}</div>
						</template>
					</el-table-column>
					<el-table-column label="站点" prop="website.code" min-width="150">
					</el-table-column>
					<el-table-column label="国家" prop="countryCode" min-width="100">
					</el-table-column>
					<el-table-column label="spu" prop="spu" min-width="150">
					</el-table-column>
					<el-table-column label="主站spu" prop="spu1" min-width="150">
					</el-table-column>
					<el-table-column label="子站spu" prop="spu2" min-width="150">
					</el-table-column>
					<el-table-column label="商品图片" min-width="150">
						<template slot-scope="scope">
							<div v-if="scope.row.pic" class="table-img-box">
								<el-image :src="scope.row.pic" :preview-src-list="[scope.row.pic]"></el-image>
							</div>
						</template>
					</el-table-column>
					<el-table-column label="订单数" prop="orderQuantity" min-width="150" sortable="custom">
					</el-table-column>
					<el-table-column label="销量数" prop="quantity" min-width="150" sortable="custom">
					</el-table-column>
					<el-table-column label="销售额" prop="totalPrice" min-width="150" sortable="custom">
					</el-table-column>
					<el-table-column label="商品售价" prop="price" min-width="150">
					</el-table-column>
					<el-table-column label="品类" prop="category" min-width="400">
					</el-table-column>
					<el-table-column label="在架状态" min-width="150">
						<template slot-scope="scope">
							<div v-if="scope.row.available !== null">
								{{scope.row.available ? '在架' : '下架'}}
							</div>
						</template>
					</el-table-column>
					<el-table-column label="上架时间" min-width="200" sortable="custom" prop="info.published_at">
						<template slot-scope="scope">
							{{scope.row.publishedAt|formatDate}}
						</template>
					</el-table-column>
				</el-table>
				<el-pagination class="mt-3" style="text-align:right;" @size-change="handleSizeChange"
					@current-change="handleCurrentChange" :current-page.sync="page.current" :page-size="page.size"
					:page-sizes="page.sizes" layout="prev, pager, next, sizes, jumper" :total="page.total">
				</el-pagination>
			</template>
		</el-card>
		<category-dialog 
			:visible.sync="visible.category"
			:options="productCategories"
			:categories.sync="queryForm.categories"
		></category-dialog>
		<el-dialog title="选择国家" :visible.sync="visible.country" :before-close="handleClose">
			<el-select v-model="countryCodes" size="mini" clearable filterable multiple class="w-100">
				<el-option v-for="country in countries" :label="country.name" :value="country.code" :class="{'d-none': country.code === 'ALL'}">
					<div class="d-flex flex-row align-items-center justify-content-between">
						<div>{{country.name}}</div>
						<div>{{country.code}}</div>
					</div>
				</el-option>
			</el-select>
			<div slot="footer" class="dialog-footer">
				<el-button @click="visible.country = false">取 消</el-button>
				<el-button type="primary" @click="updateCountries">确 定</el-button>
			</div>
		</el-dialog>
	</main-container>
</template>

<script>
	import Moment from 'moment'
	import page from '@/common/mixins/page.js'
	import common from '@/common/mixins/common.js'
	import {mapActions, mapState} from 'vuex'
	import {fetchJSONByApis} from '@/apis'
	import Lodash from 'lodash'
	import CategoryDialog from '@/components/dialog/category-dialog.vue'
	export default {
		mixins: [page, common],
		inject:['adminLayout'],
		components: {
			CategoryDialog
		},
		data() {
			return {
				q: {
					createdMinStr: Moment().add(-7, 'days').format('YYYY-MM-DD'),
					createdMaxStr: Moment().add(-1, 'days').format('YYYY-MM-DD')
				},
				queryForm: {
					websites: '',
					spus: '',
					categories: [],
					date: [
						Moment().add(-7, 'days').format('YYYY-MM-DD'),
						Moment().add(-1, 'days').format('YYYY-MM-DD')
					],
					aggregate: false,
					aggregateBys: [],
					countryCodes: [],
					publishAt: null
				},
				tableData: [],
				websites: [],
				hasErr: false,
				fetchOnCreate:false,
				allCategories: {},
				productCategories: [],
				sortBy: 'order_quantity desc',
				visible: {
					category: false,
					country: false
				},
				countryCodes: []
			}
		},
		computed: {
			...mapState({
				countries: state => state.content.countries
			})
		},
		mounted() {
			this.__init()
			this.$store.dispatch('getCountries')
		},
		methods: {
			...mapActions([
				'fetchSales',
				'exportSales',
				'getAllChannels'
			]),
			__init() {
				this.hasErr = false
				this.adminLayout.showLoading()
				this.getAllChannels()
				.then(data => {
					this.websites = Object.keys(data).map(key => {
						return {
							key,
							value: data[key]
						}
					})
					const apis = [this.$store.dispatch('feeds-content-product/getCategories', {websiteCode: 'HKC'})]
					fetchJSONByApis(...apis)
					.then(results => {
						const convert2Tree = (array) => {
							if (!Array.isArray(array)) return []
							return array.filter(v => {
								return /^women/i.test(v.completeName)
							}).map(v => {
								const children = convert2Tree(v.childs)
								const _v = {
									...v,
									label: v.name
								}
								return children.length > 0 ? {..._v, children} : _v
							})
						}
						results.forEach((result,index) => {
							this.allCategories['HKC'] = convert2Tree(result)
						})
						this.productCategories = this.allCategories['HKC']
						this.queryForm.websites = this.websites[0].key
						this.q.websites = this.queryForm.websites
						this.getList()
					})
					.catch(error => {
						this.hasErr = true
						this.adminLayout.hideLoading()
					})
				})
				.catch(error => {
					this.hasErr = true
					this.adminLayout.hideLoading()
				})
			},
			searchEvent() {
				this.q = {
					...this.queryForm,
					spus: this.queryForm.spus.match(/[\w-]+/g) || [],
					createdMinStr: this.queryForm.date[0],
					createdMaxStr: this.queryForm.date[1],
					publishedAtMinStr: this.queryForm.publishAt && this.queryForm.publishAt[0],
					publishedAtMaxStr: this.queryForm.publishAt && this.queryForm.publishAt[1]
				}
				console.log(this.q)
				this.page.current = 1
				this.getList()
			},
			// Minix
			async getListUrl() {
				const trigger = this.trigger
				return this.fetchSales({
					params: {
						...this.page,
						...this.q,
						sortBy: this.sortBy
					},
					trigger
				})
			},
			handleList(content) {
				this.tableData = content
			},
			sortChange(column) {
				const {prop, order} = column
				const sortKey = Lodash.snakeCase(prop).replace(/^info_/, 'info.')
				if (order === 'ascending') {
					this.sortBy = `${sortKey} asc`
				} else if (order === 'descending') {
					this.sortBy = `${sortKey} desc`
				} else {
					this.sortBy = ''
				}
				this.page.current = 1
				this.getList()
			},
			downloadTask() {
				this.$store.commit('download-manager/addTask', {
					action: `exportSales`,
					payload: {...this.q},
					title: `Sales(${this.q.createdMinStr}~${this.q.createdMaxStr})`,
					dispatch: this.$store.dispatch,
					responseType: 'blob'
				})
			},
			triggerCountry() {
				this.countryCodes = [...this.queryForm.countryCodes]
				this.visible.country = true
			},
			updateCountries() {
				this.queryForm.countryCodes = [...this.countryCodes]
				this.visible.country = false
			}
		}
	}
</script>

<style lang="scss">
	.sale-aggregate-checkbox-group {
		.el-checkbox {
			margin-bottom: 5px;
			.el-checkbox__label {
				font-size: 90%;
			}
		}
	}
</style>
